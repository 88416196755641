import React, { Component } from 'react';
import axios from "axios";


import pestCloudAxios from "../../utils/pestCloudAxios";

let JSZip = require("jszip");

class NewJob extends Component {

    constructor(props) {
        super(props)

        this.state = {
        	job:{},

            title: '',
            billingCode: '',
            uploadFile: null,
            anyForbiddenFiles: [],
            allPstFiles: [],
            allHpFiles: [],
            pstFile: null,
            useHPFile: false,
            isHPFile: false,
            pestcheckRun: false,
            useWhisper: false,

            analyzingPCost: false,
            zip_error: null,

            isUploading:false
        }
        this.pstFileContents={}
    }

    runPCost(){
    	let self=this


		var filename = self.state.pstFile;
		var filedata = self.pstFileContents[filename]

		var formData = new FormData();
		formData.append('filename', filename);
		formData.append('filedata', filedata);



    	let job = {
    		pstFile:filename
    	}

        pestCloudAxios.post("/pcost",formData)
        .then(res => {
            console.log({res});

            if (res.data.data) {
           		 console.log('OK 1');
           		 console.log(res.data.data);
                if (res.data.data.toLowerCase().indexOf('some specifications') >= 0) {

           		 console.log('OK 2');

                    var resLines = (res.data.data || '').split('Some specifications:-')[1].split('\n')
                    var kvp = resLines.map(function(rl) {
                        if (rl.indexOf(':')) {
                            var kv = rl.split(':')
                            if (kv.length > 1) {
                                var kvres = {}
                                var kk = kv[0].trim();
                                var vv = kv[1].trim();
                                if (kk && kk.length && vv && vv.length) {
                                    kvres.name = kk;
                                    kvres.value = vv;
                                    return kvres
                                }
                            }
                        }
                    })
                    var values = kvp.filter(function(t) {
                        return t
                    })
                    var adjParam = 1;
                    var maxParam = 1;
                    var maxIter = 1;

                    values.map(function(vv, vx) {
                        if (vx == 1 && !isNaN(+vv.value)) {
                            adjParam = +vv.value
                        }
                        if (vx < 6 && !isNaN(+vv.value)) {
                            maxParam = Math.max(maxParam, +vv.value)
                        }
                        if (vx == 6 && !isNaN(+vv.value)) {
                            maxIter = Math.max(maxIter, +vv.value)
                        }
                    })

					job.parameterCount=adjParam
					job.runCount=maxParam
					job.iterationCount=maxIter
					job.factors=values

                }
                else {
                	job.error = res.data.data.replace(/\*/gi,'').split('\n').filter((l)=>{return l.toLowerCase().indexOf('pcost')<0 && l.trim().length}).join('\n')
                }

        		self.setState({job})
            }
        })
        .catch(error => {
        	// console.log({error})
            job.error = error//.replace(/\*/gi,'').split('\n').filter((l)=>{return l.toLowerCase().indexOf('pcost')<0 && l.trim().length}).join('\n')
        	self.setState({job})
        });

    }

	cachePstFile(zip,name){
		var self = this;
		zip.files[name].async('string').then(function(content) {
			self.pstFileContents[name]=content

			if(name ==self.state.pstFile){
				window.setTimeout(()=>{
					self.runPCost()
				},500)
			}
		});
	}

    onFile(e){
    	let self=this
    	let file = e.target.files[0]

    	let zip = new JSZip()

        zip.loadAsync(file)
            .then((zip)=>{
                var allPst = []
                var allHp = []
                var anyForbidden = []
                    // model.zip, model_ow.zippest_hp.exe, pest_hp.lic,  microsoft*.dll newtonsoft*.dll system.spatial.dll *microsoft*.rtf pest_result_full\\*_taskmaster* >nul)"
                var allForbidden = [
                    'pest_hp.zip',
                    'pest_hp.exe',
                    'pwhisp_hp.exe',
                    'write_license.exe',
                    'genie.exe',
                    'azuretools.zip',
                    'azcopy.exe',
                    'license (microsoft azure storage tools).rtf',
                    'microsoft.windowsazure.storage.datamovement.dll',
                    'microsoft.windowsazure.storage.dll',
                    'microsoft.windowsazure.storage.tabledatamovement.dll',
                    'third party notice (microsoft azure storage tools).rtf',
                    'microsoft.data.edm.dll',
                    'microsoft.data.odata.dll',
                    'microsoft.data.services.client.dll',
                    'newtonsoft.json.dll',
                    'system.spatial.dll'
                ]


                for (var ff in zip.files) {
                    var fflcs = ff.toLowerCase()
                    if (fflcs.indexOf('_macos') >= 0) {
                        continue
                    }
                    if (allForbidden.indexOf(fflcs) >= 0) {
                        anyForbidden.push(ff)
                    }
                    if (fflcs.slice(-4) == '.pst') {
                        allPst.push(ff)
                        self.cachePstFile(zip, ff)
                    }
                    if (fflcs.slice(-3) == '.hp') {
                        allHp.push(ff)
                    }
                }

                // console.log({zip:zip})

                var usePstFile = allPst.length ? allPst[0] : null
                var hpmExists = false;

                if (usePstFile && allHp.length) {
                    hpmExists = allHp.map(function(xi) {
                        return xi.toLowerCase().trim()
                    }).indexOf(usePstFile.toLowerCase().trim().replace('.pst', '.hp')) >= 0 ? true : false
                }
                // if(allPst.length){
                //   self.runPCost(allPst[0])

                // }

                self.setState({
                    test: true,
                    uploadFile: file,
                    anyForbiddenFiles: anyForbidden,
                    allPstFiles: allPst,
                    allHpFiles: allHp,
                    pstFile: usePstFile,
                    useHPFile: hpmExists,
                    isHPFile: hpmExists,
                    pestcheckRun: false,
                    useWhisper: false
                });
            })
            .catch((error)=>{
            	console.log({error})
                self.setState({
                    analyzingPCost: false,
                    zip_error: error,
                    // zip_id:new Date().getTime()
                })

            })



    }

    uploadFileInBlocks() {
        var self = this;

        if (self.totalBytesRemaining > 0) {
            console.log("current file pointer = " + self.currentFilePointer + " bytes read = " + self.maxBlockSize);
            var fileContent = self.state.uploadFile.slice(self.currentFilePointer, self.currentFilePointer + self.maxBlockSize);
            var blockId = self.blockIdPrefix + self.pad(self.blockIds.length, 6);
            console.log("block id = " + blockId);
            self.blockIds.push(btoa(blockId));
            self.reader.readAsArrayBuffer(fileContent);
            self.currentFilePointer += self.maxBlockSize;
            self.totalBytesRemaining -= self.maxBlockSize;
            if (self.totalBytesRemaining < self.maxBlockSize) {
                self.maxBlockSize = self.totalBytesRemaining;
            }
        }
        else {
            self.commitBlockList();
        }
    }
    commitBlockList() {
        var self = this;
        var uri = self.submitUri + '&comp=blocklist';
        console.log(uri);
        var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
        for (var i = 0; i < self.blockIds.length; i++) {
            requestBody += '<Latest>' + self.blockIds[i] + '</Latest>';
        }
        requestBody += '</BlockList>';
        // console.log(requestBody);

        fetch(uri, {
		  method: 'PUT', // or 'PUT'
		  headers: {
		    'x-ms-blob-content-type': self.fileType,
		    'Content-Length': requestBody.length,
		  },
		  body: requestBody,
		})
		.then((response) => response.text())
		.then((data) => {
			console.log('Success:', data);
			self.setState({isUploading:false},()=>{self.props.close()})
		})
		.catch((error) => {
			console.error('Error:', error);
			self.setState({isUploading:false},()=>{self.uploadError(error)})
		})

        // $.ajax({
        //     url: uri,
        //     type: "PUT",
        //     data: requestBody,
        //     beforeSend: function(xhr) {
        //         xhr.setRequestHeader('x-ms-blob-content-type', self.fileType);
        //         xhr.setRequestHeader('Content-Length', requestBody.length);
        //     },
        //     success: function(data, status) {
        //         console.log(data);
        //         console.log(status);

        //         var newModel = self.state.newModel;
        //         if (newModel.toPlainObject) {
        //             newModel = newModel.toPlainObject()
        //         }
        //         console.log({
        //             uploadComplete: event,
        //             m: newModel
        //         })
        //         self.props.modelDetails(newModel)
        //         self.props.closeLightbox()

        //     },
        //     error: function(xhr, desc, err) {
        //         console.log(desc);
        //         console.log(err);
        //     }
        // });

    }
    pad(number, length) {
        var str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    }


    uploadProgress(event) {
    	let self=this
        var progressPct = 0;
        if (event && event.loaded && event.total) {

            self.bytesUploaded = event.loaded;
			self.percentComplete = Math.floor(event.loaded * 100 / event.total);


			self.setState({tick:new Date().getTime()})
        }

    }
    uploadComplete(event) {
    	this.setState({isUploading:false},()=>{this.props.close()})
    }
    uploadFailed(event) {
    	this.setState({isUploading:false},()=>{this.uploadError(event)})
    }
    uploadCanceled(event) {
    	this.setState({isUploading:false},()=>{this.uploadError(event)})
    }

    createJob(){

		let self=this
		self.setState({isUploading:true},()=>{
			let job = self.state.job
			job.useHPFile = self.state.useHPFile
			job.title = self.state.title
			job.billingCode = self.state.billingCode
			delete job['factors']

			axios.post('/api/jobs',job).then( (data) => {
				console.log(`Job created successfully`);
				const newJob = data.data
				console.log({newJob})

				//upload the file
				let uploadFileName = newJob.objectId.toLowerCase() 
				axios.post('/api/azure/storage/prepare',{container:newJob.user.toLowerCase(),name:uploadFileName}).then( (storageData) => {
					console.log(`Storage SAS created successfully`);
					// let newJob = data.data
					let storage = storageData.data
					console.log({storage})
					//storage.sas.uploadUrl
					//storage.sas.packageUrl

					let pool = (newJob.user +'_'+newJob.objectId).toLowerCase()

					axios.put('/api/jobs/'+newJob.objectId,{
						packageUrl:storage.sas.packageUrl,
						summaryUrl:storage.sas.summaryUrl,
						resultUrl:storage.sas.resultUrl,
						pool
					}).then( (data) => {
						console.log(`Job updated successfully`);
						let updatedJob = data.data

						self.uploadZip(storage.sas.containerUrl,storage.sas.uploadUrl,storage.sas.packageName)

					}).catch((e) => {
						console.log('2-Creation failed, Error ',e)
						throw(e)
					// self.setState({errorMessage:e})
					});


			      //upload the file

			    }).catch((e) => {
			    	console.log('1-Creation failed, Error ',e)
					throw(e)
				});

		    }).catch((e) => {
		
		    	console.log('0-Creation failed, Error ',e)
				self.setState({isUploading:false})
				// self.setState({errorMessage:e})
			});
		})


    }

    uploadError(err){
    	console.log(err)
    }

    uploadZip(containerUrl,uploadUrl,fileName){
    	let self = this 

        if (self.state.uploadFile) {
            self.fileSize = self.state.uploadFile.size
            self.fileType = self.state.uploadFile.type

            self.percentComplete = 0;
            self.bytesUploaded = 0;

            console.log('Upload Size: ' + self.fileSize)
            if (self.fileSize <= 64 * 1024 * 1024) {
                console.log('Normal')
                    //if its less than 64 MB do it the normal way

                var xhr = new XMLHttpRequest();

                // xhr.withCredentials = true;
                xhr.upload.addEventListener("progress",(e)=>{ self.uploadProgress(e)}, false);
                xhr.addEventListener("load",(e)=>{ self.uploadComplete(e)}, false);
                xhr.addEventListener("error",(e)=>{ self.uploadFailed(e)}, false);
                xhr.addEventListener("abort",(e)=>{ self.uploadCanceled(e)}, false);
                xhr.open("PUT", uploadUrl);
                var dt = new Date()
                xhr.setRequestHeader("x-ms-date", dt.toGMTString());
                xhr.setRequestHeader("x-ms-version", "2015-04-05");
                xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
                xhr.send(self.state.uploadFile)

            }
            else {
                //use the chunked uploader
                console.log('Chunked')

                self.blockIds = new Array();
                self.blockIdPrefix = "block-";
                self.maxBlockSize = 512 * 1024;
                self.currentFilePointer = 0;
                self.totalBytesRemaining = 0;
                if (self.fileSize < self.maxBlockSize) {
                    self.maxBlockSize = self.fileSize;
                }
                self.totalBytesRemaining = self.fileSize;
                if (self.fileSize % self.maxBlockSize == 0) {
                    self.numberOfBlocks = self.fileSize / self.maxBlockSize;
                }
                else {
                    self.numberOfBlocks = parseInt(self.fileSize / self.maxBlockSize, 10) + 1;
                }
                var baseUrl = containerUrl;
                var indexOfQueryStart = baseUrl.indexOf("?");
                self.submitUri = baseUrl.substring(0, indexOfQueryStart) + '/' + fileName + baseUrl.substring(indexOfQueryStart);
                console.log(self.submitUri);


                self.reader = new FileReader();

                self.reader.onloadend = function(evt) {
                    if (evt.target.readyState == FileReader.DONE) { // DONE == 2
                        var uri = self.submitUri + '&comp=block&blockid=' + self.blockIds[self.blockIds.length - 1];
                        var requestData = new Uint8Array(evt.target.result);


                        fetch(uri, {
                                method: 'PUT', // or 'PUT'
                                headers: {
                                    'x-ms-blob-type': 'BlockBlob'
                                },
                                body: requestData,
                            })
                            .then((response) => response.text())
                            .then((data) => {
                                console.log('Success:', data);
                                self.bytesUploaded += requestData.length;

                                self.percentComplete = Math.floor(parseFloat(self.bytesUploaded) * 100 / parseFloat(self.fileSize));
                                // progressPct = Math.floor(event.loaded*100/event.total)
                                // console.log('Uploading...: ' + percentComplete + '%');

								self.setState({tick:new Date().getTime()},()=>{
									self.uploadFileInBlocks()
								})
                                // self.uploadFileInBlocks();
                            })
                            .catch((error) => {
                                console.error('Error:', error);
                                self.setState({isUploading:false},()=>{self.uploadError(error)})
                            })

                        // $.ajax({
                        //     url: uri,
                        //     type: "PUT",
                        //     data: requestData,
                        //     processData: false,
                        //     beforeSend: function(xhr) {
                        //         xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
                        //         //xhr.setRequestHeader('Content-Length', requestData.length);
                        //     },
                        //     success: function(data, status) {
                        //         console.log(data);
                        //         console.log(status);
                        //         self.bytesUploaded += requestData.length;

                        //         var percentComplete = Math.floor(parseFloat(self.bytesUploaded) * 100 / parseFloat(self.fileSize));
                        //         // progressPct = Math.floor(event.loaded*100/event.total)
                        //         $('.uploadLabel').text('Uploading...: ' + percentComplete + '%');
                        //         self.uploadFileInBlocks();
                        //     },
                        //     error: function(xhr, desc, err) {
                        //         console.log(desc);
                        //         console.log(err);
                        //     }
                        // });
                    }
                };
                self.uploadFileInBlocks()
            }
        }
    }

    render() {
        let self = this

        let canCancel = true
        let canCreate = false
        let pstOptions=(
        	<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
				Please upload file
			</h2>
        )
        if(self.state.allPstFiles && self.state.allPstFiles.length){
			pstOptions=(
	        	<React.Fragment>
	        		<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
						PEST Control File:
					</h2>
					<select className={"form-control"} disabled={self.state.isUploading} value={self.state.pstFile} onChange={(e)=>{self.setState({pstFile:e.target.value,pestcheckRun:false},self.runPCost)}}>
						<option value="">Choose</option>
						{self.state.allPstFiles.map((pv,pk)=>{
			                return (<option key={"pst_"+pk} value={pv}>{pv}</option>)
			            })}
					</select>
					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
						HP Starter File:
					</h2>
					<select className={"form-control"} disabled={self.state.isUploading} value={self.state.useHPFile?'yes':'no'} onChange={(e)=>{self.setState({useHPFile:e.target.value=='yes'?true:false})}}>
						<option value="no">No</option>
						<option value="yes">Yes</option>
					</select>
					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
						PESTChek was run:
					</h2>
					<select className={"form-control"} disabled={self.state.isUploading} value={self.state.pestcheckRun?'yes':'no'} onChange={(e)=>{self.setState({pestcheckRun:e.target.value=='yes'?true:false})}}>
						<option value="no">No</option>
						<option value="yes">Yes</option>
					</select>

	        	</React.Fragment>
	        )
        }

        let jobAnalysis = null
        if(self.state.job){
        	if(self.state.job.error){
        		jobAnalysis = (

    				<div className="col-9">
						<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
							Error
						</h2>
						<p style={{whiteSpace:'pre'}}>
							{JSON.stringify(self.state.job.error)}
						</p>
    				</div>
        		)
        	}
        	if(self.state.job.factors && self.state.job.factors.length){

        		jobAnalysis = (

    				<div className="col-9">
						<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
							Specifications
						</h2>
						<ul>
							{self.state.job.factors.slice(0,3).map((f,fx)=>{
								return <li key={'s_'+fx}>{f.name}:<b>{f.value}</b></li>
							})}
						</ul>
    				</div>
        		)
        		if(self.state.pestcheckRun && self.state.title){
        			canCreate = true
        		}
        	}
        }


        if(self.state.isUploading){
        	canCreate = false
        	canCancel = false
        	
        	jobAnalysis = (


    			<div className="col-10">
	                <div className="progress" style={{marginTop:'20px'}}>
						<div 
							className="progress-bar bg-primary progress-bar-striped" 
							role="progressbar" 
							aria-valuenow={self.percentComplete||0} 
							aria-valuemin="0" 
							aria-valuemax="100"
							style={{width:(self.percentComplete||0)+'%'}}>
							<span className="sr-only">{self.percentComplete||0}% Complete (success)</span>
						</div>
	                </div>
	                <br />
					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
						Uploading: {(self.percentComplete||0)+'%'}....Do not close this window
					</h2>
                </div>
        	)
        }


    	return (
    		<div style={{position:'relative',background:'white',width:'70vw',height:'90vh',zIndex:1000}}>

				<div className="row" style={{marginTop:'2vmin'}}>
					<div className="col-1" />
					<div className="col-sm-6">
						<h1 className="m-0 text-dark text-left">
							New Job
						</h1>
					</div>{/* /.col */}							
				</div>{/* /.row */}
    			<div className="row" style={{maxHeight:'66vh',overflow:'scroll'}}>

    				<div className="col-1" />
    				<div className="col-4">
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						Title:
    					</h2>
    					<input className={"form-control"} disabled={self.state.isUploading} type="text" value={self.state.title} onChange={(e)=>{
    						self.setState({title:e.target.value.replace(/[^a-z0-9\ \-\.\_]/gi,'').replace('  ',' ').slice(0,40)})
    					}} />
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						Billing Code:
    					</h2>
    					<input className={"form-control"} disabled={self.state.isUploading} type="text" value={self.state.billingCode} onChange={(e)=>{
    						self.setState({billingCode:e.target.value.replace(/[^a-z0-9\-\.\_]/gi,'').slice(0,20).toUpperCase()})
    					}} />
    					<h2 className="lead text-left" style={{marginTop:'2vmin'}}>
    						File:
    					</h2>
    					<input type="file" disabled={self.state.isUploading} onChange={(e)=>{self.onFile(e)}} />
    				</div>
    				<div className="col-2" />
    				<div className="col-4">
    					{pstOptions}
    				</div>

    				<div className="col-1" />
    				<div className="col-1" />


    				{jobAnalysis}
    			</div>
				<div className="row" style={{position:'absolute',bottom:'10px',left:'0px',width:'100%'}}>
					<div className="col-1"/>
					<div className="col-3">
						<button type="button" className={"btn btn-block btn-primary"} disabled={!canCancel} onClick={()=>{self.props.close()}}>Cancel</button>
					</div>
					<div className="col-4">
					</div>
					<div className="col-3">
						<button type="button" className={"btn btn-block btn-success"} disabled={!canCreate} onClick={()=>{self.createJob()}}>Create</button>
					</div>
				</div>
			</div>
		);
	}
}

export default NewJob;

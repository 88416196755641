import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import fs from "fs";

import PhotoEditor from "../photoEditor/photoEditor";


class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting:false,
      user: {
                id: "",
                username: "",
                email: "",
                firstName: "",
                lastName: "",
                title: ""
              },
      error_message: null,
      avatar: "",
      ut:this.parseJwt(),
      userId: window.location.href.split('/user/')[1].split('/').slice(0)[0],
    };

    this.fetch()
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }


  fetch(){
    let self=this
    // console.log({fetch:"/api/user/"+this.state.userId})
    axios
      .get("/api/users/"+this.state.userId)
      .then(res => {
        if (res.data) {
          let user = res.data
          delete user['password']
          self.setState({user})
        }
        else{
          self.setState({user:{
                    id: "",
                    username: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    title: ""
                  }}) 
        }
        // else if (res.data.result === "error") {
          // console.log({user:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }


  componentDidMount() {
  }

  updateField(field,value){
    let user = this.state.user
    user[field]=value
    this.setState({user})
  }

  updateUser(){
    let self=this
    let user = this.state.user
    if(user && user.password && user.password.trim().length<1){
      delete user['password']
    }

    console.log({updating:user})
    axios.put('/api/users/'+user.objectId,{user}).then( (data) => {
        console.log(`User updated successfully`);
        console.log(data)

        swal({
          title:"Success!",
          text:'User updated successfully',
          type:"success",
          timer:1000
        }).then(value => {
          self.fetch()
        });
      }).catch((e) => {
        console.log('Edit failed, Error ',e)
      // self.setState({errors:e.response.data})
    });

  }

  render(){
    let self=this
    let errors={}
    let touched={}

    let userTypeSelection=null
    let adminMenu = null
    if(this.state.ut.isAdmin){
      userTypeSelection = (
        <React.Fragment key="user_types">
            <div className="col-3 form-check  float-sm-right">
              <input className="form-check-input" type="checkbox" checked={this.state.user.isAdmin||false} onChange={(e)=>{self.updateField('isAdmin',e.target.checked)}}/>
              <label className="form-check-label">Admin</label><br />
              <input className="form-check-input" type="checkbox" checked={this.state.user.isActive||false} onChange={(e)=>{self.updateField('isActive',e.target.checked)}}/>
              <label className="form-check-label">Active</label><br />
            </div>
        </React.Fragment>
      )

      adminMenu=(

                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="/admin">Admin</a></li>
                  <li className="breadcrumb-item active"><a href="/admin/users">Users</a></li>
                </ol>
      )
    }
    return(
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                User: {[this.state.user.firstName,this.state.user.lastName].join(' ').trim()}
                </h1>
              </div>{/* /.col */}
              <div className="col-sm-6">
                {adminMenu}
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="offset-md-3 col-md-6">
                {/* general form elements */}
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">update profile</h3>
                  </div>


                  <div className="card-body">

                    <div className="form-group">
                        {userTypeSelection}

                        <div className="col-3 text-center">
                          <img src={this.state.user.image} alt="" className="img-circle img-fluid" />
                          <br/>&nbsp;

                        </div>


                      <div className="input-group">
                        <div className="custom-file">
{                        
                          // <input
                          //   type="file"
                          //   onChange={e => {
                          //     e.preventDefault();

                          //     let file = e.target.files[0]
                          //     console.log({file})
                          //     var reader = new FileReader();
                          //     reader.onload = function(e) {
                          //       var img = new Image();
                          //       img.onload = function () {

                          //             var canvas = document.createElement("canvas");
                          //             var ctx = canvas.getContext("2d");
                          //             ctx.drawImage(img, 0, 0);
                       
                          //             var MAX_WIDTH = 200;
                          //             var MAX_HEIGHT = 200;
                          //             var width = img.width;
                          //             var height = img.height;
                       
                          //             if (width > height) {
                          //                 if (width > MAX_WIDTH) {
                          //                     height *= MAX_WIDTH / width;
                          //                     width = MAX_WIDTH;
                          //                 }
                          //             } else {
                          //                 if (height > MAX_HEIGHT) {
                          //                     width *= MAX_HEIGHT / height;
                          //                     height = MAX_HEIGHT;
                          //                 }
                          //             }
                          //             canvas.width = width;
                          //             canvas.height = height;
                          //             var ctx = canvas.getContext("2d");
                          //             ctx.drawImage(img, 0, 0, width, height);
                       
                          //             let dataurl = canvas.toDataURL(file.type);
                          //             console.log({dataurl,width,height,src:e.target.result})
                          //             self.updateField('image',dataurl)
                          //       }
                          //       img.src = e.target.result;
                 
                          //     };
                          //     reader.readAsDataURL(file);
                              
                          //   }}
                          //   name="avatars"
                          //   className={
                          //     errors.email && touched.email
                          //       ? "form-control is-invalid"
                          //       : "form-control"
                          //   }
                          //   accept="image/*"
                          //   id="avatars"
                          //   className="custom-file-input"
                          //   id="exampleInputFile"
                          // />
                          // <label className="custom-file-label" htmlFor="exampleInputFile">
                          //   Choose file
                          // </label>
}
                          <PhotoEditor 
                            text="Edit Photo" 
                            onImageBase64={(data)=>{
                              self.updateField('image',data.imageB64)
                            // console.log({data})
                            }}/>
                        </div>



                      </div>
                    </div>

                    <input type="hidden" name="id" value={this.state.user._id||''} />

                    <div className="form-group has-feedback">
                      <label htmlFor="username">Username</label>
                      <input
                        onChange={(e)=>{self.updateField('username',e.target.value)}}
                        value={this.state.user.username}
                        type="text"
                        className={
                          errors.username && touched.username
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="username"
                        placeholder="Enter UserName"
                      />

                      <label htmlFor="password">Password</label>
                      <input
                        onChange={(e)=>{self.updateField('password',e.target.value)}}
                        value={this.state.user.password}
                        secureTextEntry={true}
                         type="password"
                        className={
                          errors.password && touched.password
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="username"
                        placeholder="********"
                      />



                      <label htmlFor="username">First Name</label>
                      <input
                        onChange={(e)=>{self.updateField('firstName',e.target.value)}}
                        value={this.state.user.firstName}
                        type="text"
                        className={
                          errors.firstName && touched.firstName
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="first_name"
                        placeholder="Enter First Name"
                      />
                      {errors.firstName && touched.firstName ? (
                        <small id="passwordHelp" className="text-danger">
                          {errors.firstName}
                        </small>
                      ) : null}
                    </div>
                    <div className="form-group has-feedback">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        onChange={(e)=>{self.updateField('lastName',e.target.value)}}
                        value={this.state.user.lastName}
                        type="text"
                        className={
                          errors.lastName && touched.lastName
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="last_name"
                        placeholder="Enter Last Name"
                      />
                      {errors.lastName && touched.lastName ? (
                        <small id="passwordHelp" className="text-danger">
                          {errors.lastName}
                        </small>
                      ) : null}
                    </div>
                    <div className="form-group has-feedback">
                      <label htmlFor="email">Email</label>
                      <input
                        onChange={(e)=>{self.updateField('email',e.target.value)}}
                        value={this.state.user.email}
                        type="text"
                        className={
                          errors.email && touched.email
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="email"
                        placeholder="Enter Email"
                      />
                      {errors.email && touched.email ? (
                        <small id="passwordHelp" className="text-danger">
                          {errors.email}
                        </small>
                      ) : null}
                    </div>
                    <div className="form-group has-feedback">
                      <label htmlFor="title">Title</label>
                      <input
                        onChange={(e)=>{self.updateField('title',e.target.value)}}
                        value={this.state.user.title}
                        type="text"
                        className={
                          errors.title && touched.title
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="title"
                        placeholder="Enter Title"
                      />
                      {errors.title && touched.title ? (
                        <small id="passwordHelp" className="text-danger">
                          {errors.title}
                        </small>
                      ) : null}
                    </div>
                  </div>

                  <div className="card-footer">
                    <button
                      className="btn btn-block btn-primary"
                      onClick={()=>{
                        self.updateUser()
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
                {/* /.card */}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }



}

export default UserProfile;

import React, { Component } from 'react';

import Modal from "../../modal";
import './home.css';

class InfoHome extends Component {

    constructor(props) {
        super(props)

        this.state = {
        	isLoggedIn: this.isLoggedIn(),
        	showModal:false,
        	modalType:null
        }
    }

    isLoggedIn() {
        return localStorage.getItem("jwtToken") != null;
    }



  render() {
		let self=this

		let loginButton = null
		let headerDetails = null
		if(!self.state.isLoggedIn){
			loginButton = (
	            <li key="loginButton" style={{marginLeft:'20px'}}>           	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{window.location.href='/login'}}>Login</button>
	            </li>
			)

			headerDetails = (
	            <img
	              src="/dwr-logo-new.png"
	              alt="California Department Of Water Resources"
	              className="brand-image"
	              style={{ opacity: ".8", width:'300px'}}
	            />
			)
		}


		let modalView = null

		if(self.state.showModal){
			if(self.state.modalType == 'pest_cloud'){
				modalView = (
					
						<Modal>
							<div className="modal">

								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState({showModal:false})
								}}></div>
								<div style={{position:'relative',background:'white',width:'90vw',height:'90vh',zIndex:1000}}>
									
									  <div className="content-header">
									    <div className="container-fluid">
									      <div className="row mb-2">
									        <div className="col-12 text-center">
									            <img
									              src="/pest_cloud_logo.png"
									              alt="PEST_HP"
									              className="brand-image"
									              style={{ opacity: ".8", width:'50%', height:'auto', padding:'10px 50px 10px 25px'}}
									            />
							                      <h2 className="lead text-center">
							                      	Calibrate your model on the Microsoft Azure cloud using PEST_HP.
							                      </h2>
									        </div>{/* /.col */}
									      </div>{/* /.row */}
									    </div>{/* /.container-fluid */}
									  </div>


									    <section className="content">
									      <div className="row">
									        <div className="col-12">

								            <div className="card-body" style={{maxHeight:'64vh',overflowY:'scroll',overflowX:'hidden'}}>
								              
												
          <p>
            Running PEST in the cloud is easy.  You just need to upload your model, choose the number of compute agents, and deploy. For an 
            overview of what to expect from the PEST.cloud interface, <a href="/PESTCloud_PEST_HP.pdf" target="_blank">click here</a>.
          </p>
          <ul className="howSteps">
            <li><img src="/cloud_zip.png" style={{height:'130px',width:'130px'}} /></li>
            <li><img src="/cloud_upload.png" style={{height:'130px',width:'130px'}} /></li>
            <li><img src="/cloud_deploy.png" style={{height:'130px',width:'130px'}} /></li>
          </ul>
          <ul className="howSteps-desc">
            <li>1: Zip up all PEST and model files (including model executables)<br /></li>
            <li>2: Upload your zip file <br/>to PEST.cloud<br /></li>
            <li>3: Choose number of agents<br/>and deploy<br /></li>
          </ul>
          

          <a name="zip"/>
          <h2 style={{paddingLeft:'20px'}}>Zip</h2>
          <p>
            
            On your own computer, use a compression utility that will generate a .zip file from a folder containing all of your calibration files.  
            This includes:
          </p>
          <div style={{width:'90%',marginLeft:'5%'}}>
            <ul>
              <li>PEST files (.pst, .tpl, .ins, etc.)

                <ul style={{marginLeft:'10px'}}>
                  <li>Do not include PEST executable - PEST.cloud uses the latest version of PEST_HP automatically.</li>
                </ul> 
              </li>
              <li>All model files, Windows executables, any dependencies
                <ul style={{marginLeft:'10px'}}>
                  <li>Please ensure your programs will work without any 3rd party software installed at the system level.  Any 3rd party executables and their dependencies should be contained in your zip file.</li>
                </ul> 
              </li>
            </ul> 
          </div>

          <p>&nbsp;</p>
          <a name="upload"/>
          <h2 style={{paddingLeft:'20px'}}>Upload</h2>
          <p>
            PEST.cloud will transfer the zip file to secure cloud storage.<br />
          </p>
          <div style={{width:'90%',marginLeft:'5%'}}>
            <ul>
              <li>Upload your zip file and give your PEST case a unique name (include project number, etc.).</li> 
              <li>PEST.cloud will automatically detect any PEST control files in your upload.</li>
              <li>Select the PEST control file to use.  PEST.cloud will analyze the file and list details about your chosen PEST case for you to review. During the deployment step, PEST.cloud uses these details to provide an initial cost estimate.</li>
            </ul> 
          </div>


          <p>&nbsp;</p>
          <a name="deploy"/>
          <h2 style={{paddingLeft:'20px'}}>Deploy</h2>
          <p>
            Before any computing takes place, you will be asked to provide a few additional details about your PEST case, including:
          </p>

          <div style={{width:'90%',marginLeft:'5%'}}>
            <ul>
              <li>Model run time - This is important in order to get a good estimate of the expected cost.</li> 
              <li>Number of compute agents desired - See <a href='#considerations'>below</a> for example costs and considerations.</li>
              <li>Confirmation of parameter and iteration counts.</li> 
              <li>Type of compute agent - If your model requires more than 4GB of RAM, you have an option to upgrade.</li>
              <li>Maximum amount to spend on deployment - you will never be charged more than this amount, unless you decide to add additional funds.</li>
            </ul> 
          </div>


          <p>
            Upon deployment, PEST.cloud unzips your contents, starts up as many virtual machines as you specified, 
            transfers your files to those machines, and then starts PEST_HP. As PEST_HP runs, you can use your browser to review, in real time, 
            the output files it produces, or download them to your desktop.   When PEST_HP has finished, PEST.cloud will run the “<a onClick={this.gotoPEST}>PEST_whisperer</a>” if desired.
          </p>

								            </div>
									        </div>
									      </div>
									      <div className="row" style={{marginTop:'3vmin'}}>
									        <div className="col-4"/>
									        <div className="col-4">
									          	<button type="button" className="btn btn-block btn-primary" onClick={()=>{self.setState({showModal:false})}}>Close</button>
									        </div>
									      </div>
									    </section>


								</div>
							</div>
						</Modal>
				)	
			}
			else if(self.state.modalType == 'pest_hp'){
			
				modalView = (
					
						<Modal>
							<div className="modal">

								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState({showModal:false})
								}}></div>
								<div style={{position:'relative',background:'white',width:'90vw',height:'90vh',zIndex:1000}}>
									
									  <div className="content-header">
									    <div className="container-fluid">
									      <div className="row mb-2">
									        <div className="col-12 text-center">
									            <img
									              src="/pest_hp_logo.png"
									              alt="PEST_HP"
									              className="brand-image"
									              style={{ opacity: ".8", width:'50%', height:'auto', padding:'10px 50px 10px 25px'}}
									            />
							                      <h2 className="lead text-center">
							                      	A version of PEST optimised for use in highly parallelized environments.
							                      </h2>
									        </div>{/* /.col */}
									      </div>{/* /.row */}
									    </div>{/* /.container-fluid */}
									  </div>


									    <section className="content">
									      <div className="row">
									        <div className="col-12">

								            <div className="card-body" style={{maxHeight:'64vh',overflowY:'scroll',overflowX:'hidden'}}>
								              
												<p>
												PEST_HP is a version of BEOPEST that is optimised for use in highly parallelized environments. 
												It also has greater tolerance for model defects which compromise finite-difference derivatives calculation. 
												Read the PEST_HP manual to see the many other things it does. When you use PEST.cloud you are automatically 
												using PEST_HP.
												</p>

												<p>
												PEST_HP is available for purchase as its own item. As the owner of PEST_HP, you can use it on your office network or on a high performance cluster to which you have access. Or you can use it to implement cloud-based calibration/uncertainty analysis on the cloud provider of your choice. At the same time, as a purchaser of PEST_HP, you are automatically credited with $500 free use of PEST.cloud.
												</p>

												<p>
												PEST_HP is available as a windows 64-bit executable. Contact us if you would like a unix version. In this case you will receive the source code (FORTRAN and C++). You can then compile it for your own platform
												</p>

												<h2 className="m-0 text-dark">
												&nbsp;<br/>
												PEST Whisperer
												</h2>
												<p>
												With PEST_HP you also receive the “PEST whisperer” – PWHISP_HP. This reads a suite of files that PEST_HP writes in which it records the progress and results of the inversion process. After reading these files, it provides as much advice as it can on whether PEST_HP’s performance was good and, if not, what PEST_HP settings may result in better performance next time.
												</p>

												<p>
												To get your copy of PEST_HP, please contact us at <a href="mailto:info@pest.cloud">info@pest.cloud</a>
												</p>

								            </div>
									        </div>
									      </div>
									      <div className="row" style={{marginTop:'3vmin'}}>
									        <div className="col-4"/>
									        <div className="col-4">
									          	<button type="button" className="btn btn-block btn-primary" onClick={()=>{self.setState({showModal:false})}}>Close</button>
									        </div>
									      </div>
									    </section>


								</div>
							</div>
						</Modal>
				)	
			}
		}

    return (
		<div className="content-wrapper" style={self.state.isLoggedIn?{}:{marginLeft:'0px'}}>
			{/* Content Header (Page header) */}
			<div className="content-header">
				<div className="container-fluid">
					<div className="row mb-2">
						<div className="col-sm-6">
							<h1 className="m-0 text-dark">
								{headerDetails}
							</h1>
						</div>{/* /.col */}
						<div className="col-sm-6">
							<ol className="breadcrumb float-sm-right">
								{loginButton}
							</ol>
						</div>
						{/* /.col */}
					</div>{/* /.row */}
				</div>{/* /.container-fluid */}
			</div>
		  {/* /.content-header */}
		  {/* Main content */}



		    <section className="content">
				<div className="card card-solid">
					<div className="card-body pb-0">
						<div className="col-12 row d-flex align-items-stretch">


				            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-stretch">
				            	<div className="card bg-light" style={{width: '100%'}}>
					                <div className="card-header text-muted border-bottom-0">
							            <img
							              src="/pest_cloud_logo.png"
							              alt="PEST.cloud"
							              className="brand-image"
							              style={{ opacity: ".8", width:'100%', height:'auto', padding:'10px 35px 10px 25px'}}
							            />	
					                </div>
					                <div className="card-body pt-0">
										<div className="row">
											<div className="col-12">
												<p>&nbsp;</p>
												<h1 className="lead text-center">
													Calibrate your model on the Microsoft Azure cloud using PEST_HP.
												</h1>
												<p>&nbsp;</p>
											</div>
										</div>
										<div className="row">
											<div className="col-3" />
											<div className="col-6">
												<button type="button" className="btn btn-block btn-primary" onClick={()=>{self.setState({showModal:true,modalType:'pest_cloud'})}}>More Info</button>
											</div>
										</div>
					                </div>
					                <div className="card-footer">
										<div className="text-center">
											<a href="http://www.sspa.com" target="_blank">
												<img
													src="/sspa_logo.png"
													alt="PEST.cloud"
													className="brand-image"
													style={{ opacity: ".8", width:'100%',maxWidth:'400px', height:'auto', padding:'10px 35px 10px 25px'}}
												/>	
											</a>
										</div>
					                </div>
				            	</div>
				            </div>

				            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-stretch">
				            	<div className="card bg-light" style={{width: '100%'}}>
					                <div className="card-header text-muted border-bottom-0">
							            <img
							              src="/pest_hp_logo.png"
							              alt="PEST_HP"
							              className="brand-image"
							              style={{ opacity: ".8", width:'100%', height:'auto', padding:'10px 35px 10px 25px'}}
							            />	
					                </div>
					                <div className="card-body pt-0">
										<div className="row">
											<div className="col-12">
												<p>&nbsp;</p>
												<h1 className="lead text-center">
													A version of PEST optimised for use in highly parallelized environments.
												</h1>
												<p>&nbsp;</p>
											</div>
										</div>
										<div className="row">
											<div className="col-3" />
											<div className="col-6">
												<button type="button" className="btn btn-block btn-primary" onClick={()=>{self.setState({showModal:true,modalType:'pest_hp'})}}>More Info</button>
											</div>
										</div>
					                </div>
					                <div className="card-footer">
										<div className="text-center">
											<a href="http://www.pesthomepage.org" target="_blank">
												<img
													src="/watermark_numerical_logo.png"
													alt="Watermark Numerical Computing"
													className="brand-image"
													style={{ opacity: ".8", width:'100%',maxWidth:'400px', height:'auto', padding:'10px 35px 10px 25px'}}
												/>	
											</a>
										</div>
					                </div>
				            	</div>
				            </div>


						</div>
					</div>
				</div>
			</section>
		    {modalView}
		</div>
      	);
  	}
}

export default InfoHome;

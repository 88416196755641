import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "username is Too Short!")
    .max(50, "username is Too Long!")
    .required("Username is Required"),
  password: Yup.string().required("Password is required")
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null
    };
  }
  componentDidMount() {
    if (localStorage.getItem("jwtToken") != null) {
      return this.props.history.push('/home');
    }
    let notify = this.props.match.params["notify"]
    if(notify !== undefined){
      if(notify === 'error'){
        swal("Activation Fail please try again !", '', "error")
      }else if(notify === 'success'){
        swal("Activation Success your can login !", '', "success")
      }
     
    }
  }

  submitForm = (values, history) => {
    axios
      .post("/api/users/login", values)
      .then(res => {
        console.log({res})
        if (res.data.success) {
          localStorage.removeItem("currentCustomer");
          localStorage.setItem("jwtToken", res.data.token);
          // swal("Success!", '', "success").then(value => {
            window.location='/';
          // });
        } else if (res.data.result === "error") {
          //swal("Error!", res.data.message, "error");
          swal("Error!", 'Invalid Login....Please try again.', "error");
        }
      })
      .catch(error => {
        console.log(error);
          swal("Error!", 'Invalid Login....Please try again.', "error");
        // return swal("Error!", error.message, "error");
      });
  };
  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group input-group has-feedback">
          <input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            name="username"
            onChange={handleChange}
            value={values.username}
            className="form-control"
            placeholder="Username"
            className={
              errors.username && touched.username
                ? "form-control is-invalid"
                : "form-control"
            }
          />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user"></span>
            </div>
          </div>
          {errors.username && touched.username ? (
            <small id="passwordHelp" className="text-danger">
              {errors.username}
            </small>
          ) : null}
        </div>
        <div className="form-group input-group mb-3 has-feedback">
          <input
            type="password"
            name="password"
            onChange={handleChange}
            value={values.password}
            className="form-control"
            placeholder="Password"
            className={
              errors.password && touched.password
                ? "form-control is-invalid"
                : "form-control"
            }
          />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock"></span>
            </div>
          </div>
          {errors.password && touched.password ? (
            <small id="passwordHelp" className="text-danger">
              {errors.password}
            </small>
          ) : null}
        </div>
        <div className="row">
          <div className="col-8">
          { 
            // <div className="icheck-primary">
            //   <input type="checkbox" id="remember" />
            //   <label htmlFor="remember">Remember Me</label>
            // </div>
          }
          </div>
          <div className="col-4">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary btn-block"
            >
              Sign In
            </button>
          </div>
        </div>
      </form>
    );
  };

  render() {
    return (
      <div className="login-page">
        <div className="register-box">
          <div className="register-logo">


          </div>
          <div className="card">
            <div className="card-body register-card-body">
            <a href="/">
            <img
              src="/dwr_pest_cloud_logo.png"
              alt="PEST.cloud, California Department Of Water Resources"
              className="brand-image"
              style={{ opacity: ".8", width:'100%', height:'auto'}}
            />
            </a>
              <p className="login-box-msg" style={{marginTop:'10px'}}>Sign in to start your session</p>

              <Formik
                initialValues={{
                  username: "",
                  password: ""
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.submitForm(values, this.props.history);
                  setSubmitting(false);
                }}
                validationSchema={LoginSchema}
              >
                {/* {this.showForm()}            */}
                {props => this.showForm(props)}
              </Formik>
              {
              // <p className="mb-1">
              //   <Link to="/password/forgot">I forgot my password</Link>
              // </p>
              // <p className="mb-0">
              //   <Link to="/register">Register a new membership</Link>
              // </p>
              }

            </div>

                  <div className="card-footer">
                    <div className="text-center" style={{width:'50%',display:'inline-block'}}>
                      <a href="http://www.sspa.com" target="_blank">
                        <img
                          src="/sspa_logo.png"
                          alt="PEST.cloud"
                          className="brand-image"
                          style={{ opacity: ".8", width:'100%', height:'auto'}}
                        />  
                      </a>
                    </div>
                    <div className="text-center" style={{width:'50%',display:'inline-block'}}>
                      <a href="http://www.pesthomepage.org" target="_blank">
                        <img
                          src="/watermark_numerical_logo.png"
                          alt="Watermark Numerical Computing"
                          className="brand-image"
                          style={{ opacity: ".8", width:'100%', height:'auto'}}
                        />  
                      </a>
                    </div>
                  </div>
            {/* /.form-box */}
          </div>
          {/* /.card */}
        </div>
      </div>
    );
  }
}

export default Login;

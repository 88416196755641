import React, { Component } from 'react';
import axios from "axios";


import pestCloudAxios from "../../utils/pestCloudAxios";

class DeploymentDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
        	job:this.props.job ||{},
        	tasks:[],
        	selectedTask:null,
        	selectedTaskFile:null,
        	taskFiles:{},
        	fileData:{},
        	isRefreshing:false
        }

        this.fetchTasks()
	}

	cacheFile(callback){
		let self=this

		if(!self.state.selectedTask || !self.state.selectedTaskFile){
			return
		}

		let fileData = self.state.fileData

		let task = self.state.tasks.filter((t)=>{return t.id===self.state.selectedTask})[0] || {nodeInfo:{}}
		let node = task.nodeInfo.nodeId || ''
		let path = this.state.selectedTaskFile

		let fixPath=path.replace(/[^a-z0-9\.]/gi,'_')
		if(!fileData[self.state.selectedTask]){
			fileData[self.state.selectedTask] = {}
		}
		if(!fileData[self.state.selectedTask][fixPath]){
			fileData[self.state.selectedTask][fixPath] = ''
		}

        // fetch(axios.defaults.baseURL + '/nodeFile', {
        //         method: 'POST', // or 'PUT'
        //         headers: {
        //         	'Accept':'*/*',
        //         	// 'Accept-Encoding': 'gzip, deflate, br, chunked'
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({
	       //      	pool:self.state.job.pool,
	       //      	node:node,
	       //      	path:self.state.selectedTaskFile,
	       //      	task:task.id,
	       //      	fileDetails:self.state.taskFiles[self.state.selectedTask].filter((tf)=>{return tf.name===path})[0]
	       //      }),
        //     })
        //     .then((res) => res.arrayBuffer())
      //       .then((data) => {
      //           console.log('Success:', data);
      //       })
      //       .catch((error) => {
      //           console.error('Error:', error);
      //       });
        axios
            .post("/api/nodeFile",{
            	pool:self.state.job.pool,
            	node:node,
            	path:self.state.selectedTaskFile,
            	task:task.id,
            	fileDetails:self.state.taskFiles[self.state.selectedTask].filter((tf)=>{return tf.name===path})[0]
            },{
		        // headers: {
		        // 	'Accept':'*/*',
		        //     'Accept-Encoding': '*',
		        // },
		    //     onDownloadProgress: (progressEvent) => {
				  //   // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				  //   // console.log(progressEvent.lengthComputable)
				  //   console.log(progressEvent);
				  // }
		    })
		    // .then((res) => res.body())
            .then(res => {
                if (res.data && res.data.length) {
                	fileData[self.state.selectedTask][fixPath] = res.data
                }
                
                self.setState({fileData,isRefreshing:false},callback)
                // else if (res.data.result === "error") {
                console.log({
                    fileData: res
                });
                // }
            })
            .catch(error => {
                console.log(error);
            });
		// self.setState({fileData})
	}
	fetchTasks(callback){

        let self = this
        axios
            .get("/api/tasks/" + this.state.job.pool)
            .then(res => {
                if (res.data.tasks && res.data.tasks.length) {
                    self.setState({
                        tasks: res.data.tasks
                    },callback)
                }
                else {
                    self.setState({
                        tasks: []
                    },callback)
                }
                // else if (res.data.result === "error") {
                // console.log({
                //     tasks: res.data.tasks
                // });
                // }
            })
            .catch(error => {
                console.log(error);
            });
	}

	fetchTaskFiles(callback){
		let self=this
		let task = self.state.tasks.filter((t)=>{return t.id===self.state.selectedTask})[0] || {nodeInfo:{}}
        console.log({fetchTaskFiles:{dt:new Date(),task}})
		if(!task || !task.nodeInfo || ! task.nodeInfo.nodeId){
			return
		}
		let node = task.nodeInfo.nodeId || ''

        let taskFiles = self.state.taskFiles
        if(!taskFiles[self.state.selectedTask])
        {
        	taskFiles[self.state.selectedTask] = []
        }
        axios
            .get("/api/nodeFiles/" + this.state.job.pool + '/'+ node + '/'+ this.state.selectedTask)
            .then(res => {

                if (res.data.files && res.data.files.length) {
                	taskFiles[self.state.selectedTask] = res.data.files
                }
                else {
                	taskFiles[self.state.selectedTask] = []
                }
                console.log({fetchTaskFiles:{dt:new Date(),task,taskFiles}})
                self.setState({taskFiles},callback)
                // else if (res.data.result === "error") {
                // console.log({
                //     files: res.data.files
                // });
                // }
            })
            .catch(error => {
                console.log(error);
            });
	}

    render() {
        let self = this



        let currentFileData=''
        let taskFiles = []
        let fixPath = ''
        if(self.state.selectedTask){
        	taskFiles = self.state.taskFiles[self.state.selectedTask]||[]

			fixPath=(self.state.selectedTaskFile||'').replace(/[^a-z0-9\.]/gi,'_')
	        if(self.state.selectedTaskFile && self.state.fileData[self.state.selectedTask] && self.state.fileData[self.state.selectedTask][fixPath]){
	        	currentFileData = self.state.fileData[self.state.selectedTask][fixPath]
	        }
        }

        // if(self.state.isRefreshing){
        // 	currentFileData = 'Loading: ' + fixPath + '...'
        // }
        else if(self.state.selectedTask && self.state.selectedTaskFile && !currentFileData.length){
        	currentFileData = '[Empty File]'
        }

        let pstBase = this.state.job.pstFile.replace('.pst','').toLowerCase()
		let totalFileCount = taskFiles.length
        taskFiles = taskFiles.filter((tf)=>{
        	return (tf.name.toLowerCase().indexOf(pstBase)>=0 &&
        			tf.name.toLowerCase().indexOf('_cmdlog.txt')<0) ||
					tf.name.toLowerCase().slice(-4) === '.jco' ||
					tf.name.toLowerCase().slice(-4) === '.rec' ||
					tf.name.toLowerCase().slice(-4) === '.rmr' ||
					tf.name.toLowerCase().slice(-4) === '.sen' ||
					tf.name.toLowerCase().slice(-4) === '.seo' ||
					tf.name.toLowerCase().slice(-4) === '.svd'
        })

        let primaryOutputOptions=[]
        // if(taskFiles.length){
        if(totalFileCount){
        	primaryOutputOptions.push(<option key="o_stdout" value={'stdout.txt'}>stdout.txt</option>)
        	primaryOutputOptions.push(<option key="o_stderr" value={'stderr.txt'}>stderr.txt</option>)
        }


        let sortedTasks = self.state.tasks
        sortedTasks.sort((a,b)=>{
        	if(!a || !b){
        		return 0
        	}
        	let aVal = +(a.id.replace(/[^0-9]/gi,''))
        	let bVal = +(b.id.replace(/[^0-9]/gi,''))
        	if(aVal>bVal){ return 1}
        	else if(aVal<bVal){ return -1}
        	else{return 0}
        })
    	return (
    		<div style={{position:'relative',background:'black',width:'100vw',height:'100vh',zIndex:1000}}>

				<div className="row" style={{background:'#cdcdcd'}}>
					<div className="col-3">
						<h3 className="ml-2 mt-1 text-dark text-left" style={{maxHeight: '1.3em', overflow: 'hidden', textOverflow: 'ellipsis'}}>
							{self.state.job.title}
						</h3>
					</div>
					<div className="col-2">
						<select value={self.state.selectedTask || ''} onChange={(e)=>{
							self.setState({selectedTaskFile:null,selectedTask:e.target.value},()=>{
								self.fetchTaskFiles()
							})
						}} className="form-control mt-1">
							<option>Choose Task</option>
							{sortedTasks.map((t)=>{
								return <option key={'t_'+t.id} value={t.id}>{t.id}</option>
							})}
						</select>
					</div>
					<div className="col-2" >
						<select disabled={!totalFileCount} value={self.state.selectedTaskFile || ''} onChange={(e)=>{
							self.setState({isRefreshing:true,selectedTaskFile:e.target.value},()=>{
								// self.fetchTaskFiles()
								self.cacheFile()
							})
						}} className="form-control mt-1">
							<option>Choose File</option>
							{primaryOutputOptions}
							{taskFiles.map((tf)=>{
								return <option key={'tf_'+tf.name} value={tf.name}>{tf.name.split('\\').slice(2).join('\\')}</option>
							})}
						</select>
					</div>
					<div className="col-5 text-right">
						<button 
							disabled={self.state.isRefreshing ||self.state.selectedTaskFile==null}
							onClick={(e)=>{
								self.setState({isRefreshing:true},()=>{
									self.cacheFile()	
								})
							}} 
							className="btn btn-sm btn-primary mr-1 mt-2" style={{color:'white'}}>
							<i className="fas fa-redo-alt mr-1" />
							Refresh
						</button>
						<button 
							disabled={self.state.selectedTaskFile==null}
							onClick={(e)=>{
									let fileName = self.state.selectedTaskFile
									if(fileName.split('\\').length>2 && fileName.indexOf('shared')===0){
										fileName = fileName.split('\\').slice(2).join('\\')
									}
							      var pom = document.createElement('a');
							      pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(currentFileData));
							      pom.setAttribute('download', fileName);
							      pom.click();
							// self.setState({showModal:true,modalType:'details',selectedJob:j})
							// self.deploy(j.objectId,j.pool)
							}} 
							className="btn btn-sm btn-primary mr-3 mt-2" style={{color:'white'}}>
							<i className="fas fa-file-download mr-1" />
							Download
						</button>
						
						<button onClick={(e)=>{
							self.props.terminate(self.state.job.pool)
							// self.setState({showModal:true,modalType:'details',selectedJob:j})
							// self.deploy(j.objectId,j.pool)
						}} className="btn btn-sm btn-danger mr-3 mt-2" style={{color:'white'}}>
							<i className="fas fa-skull mr-1" />
							Terminate
						</button>
						<button onClick={(e)=>{
							self.props.close()
							// self.setState({showModal:true,modalType:'details',selectedJob:j})
							// self.deploy(j.objectId,j.pool)
						}} className="btn btn-sm btn-success mr-3 mt-2" style={{color:'white'}}>
							<i className="fas fa-arrow-circle-left mr-1" />

							Back
						</button>
					</div>						
				</div>
    			<div className="row" style={{
    					position:'absolute',
    					top:'50px',
    					bottom:'10px',
    					left:'10px',
    					right:'10px',
    					overflow:'scroll',
    					color:"white",
    					whiteSpace: 'pre',
    					fontFamily: 'monospace',
    					lineHeight: '1em'
    					// background:'#888888'
    				}}>
    				{currentFileData}
    			</div>
			</div>
		);
	}
}

export default DeploymentDetails;

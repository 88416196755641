import axios from "axios";

const apiMachineAddress = 'https://www.pest.cloud'


const pestCloudAxios = axios.create({
	baseURL: apiMachineAddress
});

export default pestCloudAxios;

import axios from "axios";

const machine = window.location.href.replace('http://','').replace('https://','').split('/')[0]
// const apiMachineAddress = 'http://'+ machine + ':4000'
const apiMachineAddress = '/'
console.log({apiMachineAddress})
axios.defaults.baseURL = apiMachineAddress //'http://10.219.1.100:4000'

const setAuthToken = token => {
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;

import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Footer from "./components/footer";
import Login from "./components/login";
import Dashboard from "./components/dashboard";


import AdminUsers from "./components/admin/users";
import UserProfile from "./components/admin/userProfile";

import InfoHome from "./components/info/home";

import Jobs from "./components/jobs/jobs";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";


import axios from "axios";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

// Check for token to keep user logged in
  console.log({localStorage})
if (localStorage.jwtToken) {

  console.log('Valid Token')
    // // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // // Set user and isAuthenticated
    // store.dispatch(setCurrentUser(decoded));
    // // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
        // Logout user
        // store.dispatch(logoutUser());
        // Redirect to login
        localStorage.removeItem("currentUser");
        localStorage.removeItem("jwtToken");
        window.location.href = "./login";
    }
}
else{
  console.log('No Token')

}
const isLoggedIn = () => {
  return localStorage.getItem("jwtToken") != null;
};

// Protected Route
const SecuredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>{
      let ili = isLoggedIn()
      console.log('Redirecting...')

      return  ili ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )

    }
      // ternary condition

    }
  />
);
export default class App extends Component {
  constructor(props) {
      super(props)

      this.state={
          // customer:null
      }
      // this.checkDatabase()
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    console.log("update");
  }

  render() {
    // const {pathname} = this.props.location;
    // //
            // {isLoggedIn() && <Footer />}

            // {isLoggedIn() && <Sidebar />}
    return (
      <Router>
        <Switch>
          <React.Fragment>          
          <div>
            {isLoggedIn() && <Header />}

            <Route exact path="/" component={InfoHome} />
            <Route path="/login/:notify?" component={Login} />
            <SecuredRoute path="/dashboard" component={Dashboard} />
            <SecuredRoute path="/admin/users" component={AdminUsers} />
            <SecuredRoute path="/admin/user" component={UserProfile} />


            <SecuredRoute exact path="/jobs" component={Jobs} />
{            
            // <Route path="/register" component={Register} />
            // <Route path="/password/reset/:token" component={Passwordreset} />
            // <Route path="/password/forgot" component={Passwordforgot} />

            // <SecuredRoute path="/customers" component={Customers} />
            // <SecuredRoute path="/customer" component={Customer} />

            // <SecuredRoute path="/layaways" component={Transactions} />
            // <SecuredRoute path="/transactions" component={Transactions} />
            // <SecuredRoute path="/transaction" component={Transaction} />

            // <SecuredRoute path="/repairs" component={Repairs} />
            // <SecuredRoute path="/repair" component={Repair} />

            // <SecuredRoute path="/sales" component={Sales} />
            // <SecuredRoute path="/sale" component={Sale} />

            // <SecuredRoute path="/orders" component={Orders} />
            // <SecuredRoute path="/order" component={Order} />

            // <SecuredRoute path="/appraisals" component={Appraisals} />
            // <SecuredRoute path="/appraisal" component={Appraisal} />
            
            // <SecuredRoute path="/profile" component={Profile} />
            // <SecuredRoute path="/admin/customers" component={AdminCustomers} />
            // <SecuredRoute path="/admin/status" component={AdminStatus} />
            // <SecuredRoute path="/admin/user" component={UserProfile} />
}
            <Footer />
          </div>

          </React.Fragment>
        </Switch>
      </Router>
    );
  }
}

import React, { Component } from "react";
import swal from "sweetalert";
import { withRouter, Link } from "react-router-dom";
class Header extends Component {


  constructor(props){
    super(props);

    this.state={
      searchText:'',
      ut:this.parseJwt()
    }
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }


  Logout = () => {
    swal("Are you sure you want to sign out?", {
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope",
          className:"btn-primary"
        },
        sure: {
          text: "Sign Out",
          value: "sure",
          className:"btn-danger"
        }
      }
    }).then(value => {

      console.log({SignoutAnswer:value})
      switch (value) {
        case "sure":
            localStorage.removeItem("currentCustomer");
            localStorage.removeItem("jwtToken");
            window.location='/login'
          break;
        case "nope":
          // swal("Ok", "success");
          break;
        default:
          // swal("Got away safely!");
      }
    });
  };
  render() {
    let self=this

    let adminActions = []
      if(this.state.ut.isAdmin){
        adminActions.push(
              <Link key="a_manage_users" to={'/admin/users'} className="dropdown-item">
                <i className="fas fa-user-friends mr-2" /> Admin: Manage Team
              </Link>
        )
      }
      else{
        adminActions.push(
              <Link key="view_users" to={'/admin/users'} className="dropdown-item">
                <i className="fas fa-user-friends mr-2" /> View Team
              </Link>
        )
      }
      // <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
        {
          // <li className="nav-item">
          //   <a className="nav-link" data-widget="pushmenu" href="#">
          //     <i className="fas fa-bars" />
          //   </a>
          // </li>
        
          // <li className="nav-item">
          //   <a href="/dashboard" className="nav-link">
          //     Home
          //   </a>
          // </li>
        }
          <li className="nav-item">
            <div style={{border:'solid 0px red',height:'46px',marginTop:'-8px',marginBottom:'-8px'}}>
              <img
                src="/dwr_pest_cloud_logo.png"
                alt="PEST.cloud"
                className="brand-image"
                style={{ marginLeft:'8px', marginTop:'8px',opacity: ".8", width:'180px', height:'auto'}}
              />
            </div>
          </li>
          <li className="nav-item">
            <a href="/" className="nav-link" onClick={(e)=>{
              // localStorage.removeItem("currentJob")
            }}>
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="/jobs" className="nav-link" onClick={(e)=>{
              // localStorage.removeItem("currentJob")
            }}>
              Jobs
            </a>
          </li>
        </ul>

        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Messages Dropdown Menu */}
          {/* Notifications Dropdown Menu */}

          <li className="nav-item dropdown">

            <a className="nav-link" data-toggle="dropdown" href="#">
              <span style={{marginRight:'4px'}}>{this.state.ut.username}</span><i className="far fa-user" />
            </a>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              
              <span className="dropdown-item dropdown-header" style={{textAlign:'left'}}>
                {[this.state.ut.firstName,this.state.ut.lastName].join(' ')}<br/>
                {this.state.ut.title}
              </span>

              <div className="dropdown-divider" />
              <a className="dropdown-item" onClick={()=>{
                window.location='/admin/user/'+this.state.ut.objectId+'?t='+new Date().getTime()
              }}>
                <i className="fas fa-user-alt mr-2" /> Update Profile
              </a>
              <div className="dropdown-divider" />
              {adminActions}
              <div className="dropdown-divider" />
              <a
                onClick={() => this.Logout()}
                className="dropdown-item"
              >
                <i className="fas fa-sign-out-alt mr-2" /> Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withRouter(Header);

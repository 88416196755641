import React, { Component } from "react";
import './footer.css';

class Footer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoggedIn: this.isLoggedIn()
        }
    }
    isLoggedIn() {
        return localStorage.getItem("jwtToken") != null;
    }
    render() {
        let self = this
        return (
            <footer className="main-footer" style={self.state.isLoggedIn?{}:{marginLeft:'0px'}}>
                <div className="float-right d-none d-sm-block">
                    &nbsp;<br/>
                    <b>Version</b> 2.0.0
                </div>

                <a href="http://www.sspa.com" target="_blank">S.S. Papadopulos & Associates</a>&nbsp;|&nbsp; 
                <a href="http://www.pesthomepage.com" target="_blank">Watermark Numerical Computing</a>
                <br/>
                <strong>Copyright © {new Date().getFullYear()}</strong> All rights reserved.
            </footer>
        );
    }
}

export default Footer;

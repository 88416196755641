import React, { Component } from 'react';
import axios from "axios";
import swal from "sweetalert";

import socketIOClient from "socket.io-client";

import Modal from "../../modal";
import NewJob from "./newJob";
import DeploymentOptions from "./deploymentOptions";
import DeploymentDetails from "./deploymentDetails";

let io = {
	on:()=>{},
	off:()=>{},
}
const elapsedTime = (d1,d2) => {
  'use strict';
  const elapsed = Math.abs(d1.getTime() - d2.getTime()) / 1000;

    const diff = {};

    diff.days    = Math.floor(elapsed / 86400);
    diff.hours   = Math.floor(elapsed / 3600 % 24);
    diff.minutes = Math.floor(elapsed / 60 % 60);
    diff.seconds = Math.floor(elapsed % 60);

    let result = []
    if(diff.days>0){
    	result.push(diff.days+'D ')
    }
    result.push((diff.hours+':').padStart(3,'0'))
    result.push((diff.minutes+':').padStart(3,'0'))
    result.push((diff.minutes+'').padStart(2,'0'))

    return result
};

class Jobs extends Component {

    constructor(props) {
        super(props)

        this.state = {
        	showModal:false,
        	modalType:null,
        	selectedJob:null,
            users: [],
            jobs: [],
            pools: [],
            cloudJobs: [],
            ut: this.parseJwt(),
            poolStatus:{}
        }

        this.fetch()
    }
    componentDidMount(){

    	io = socketIOClient(axios.defaults.baseURL);

    	io.on("socketApi", (data) => {
    		console.log({ socketApi: data })
    	});
    	io.off();
    	io.on("socketApi", (data) => {
    		console.log({ doodoo: data })
    	});

    }

    statusUpdate(data){
    	let self=this
    	if(data.id){
    		let poolStatus = this.state.poolStatus
    		poolStatus[data.id] = data
    		let callback=()=>{}
    		if(data.status==='complete' || data.status==='deallocating'){
    			callback = self.fetch
    		}
    		this.setState({poolStatus},callback)
    	}
    	console.log({ statusUpdate: data })

    }
    parseJwt() {
        let token = localStorage.getItem("jwtToken");
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
            .split("")
            .map(function(c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
    }

    fetch(){
        let self = this
    	console.log({fetching:this})

    	self.fetchUsers(()=>{
    		self.fetchJobs(()=>{
    			io.off()
		    	// io.on('watch', (data) => {
		    	// 	console.log(data)
		    	// });
    			let pendingRunningJobs = (self.state.jobs||[]).filter((j)=>{
					return j.status != 'complete'
				})
				console.log({pendingRunningJobs})
				pendingRunningJobs.forEach((j)=>{
					if(j){
						const pool = j.pool
				    	io.on(pool, (data) => {
				    		self.statusUpdate(data)
				    	});

					}
				})

    		})
    	})
    }
    fetchJobs(callback){
        let self = this
        axios
            .get("/api/jobs/all")
            .then(res => {
                if (res.data && res.data.length) {
                    self.setState({
                        jobs: res.data
                    },callback)
                }
                else {
                    self.setState({
                        jobs: []
                    },callback)
                }
                // else if (res.data.result === "error") {
                console.log({
                    jobs: res
                });
                // }
            })
            .catch(error => {
                console.log(error);
            });
    }

    fetchUsers(callback) {
        let self = this

        // if(!this.state.ut.isAdmin){
        // 	window.location = '/admin/user/'+this.state.ut.objectId
        // 	return;
        // }

        axios
            .get("/api/users/all")
            .then(res => {
                if (res.data && res.data.length) {
                    self.setState({
                        users: res.data
                    },callback)
                }
                else {
                    self.setState({
                        users: []
                    },callback)
                }
                // else if (res.data.result === "error") {
                console.log({
                    users: res
                });
                // }
            })
            .catch(error => {
                console.log(error);
            });
    }
    deploy(job,pool){
        let self = this


        swal("Are your sure you want to deploy?", {
            buttons: {
                no: {
                    text: "Cancel",
                    value: "no",
          			className: "btn-danger"
                },
                yes: {
                    text: "Deploy",
                    value: "yes",
          			className: "btn-success"
                }
            }
        }).then(value => {

            switch (value) {
                case "yes":
                // console.log({deployYes:job,value})
					axios.put('/api/jobs/'+job,{status:'deploy'}).then( (data) => {
						self.fetchJobs(()=>{

				        axios
				            .get("/api/deploy/"+pool)
				            .then(res => {
				            	if(res.data && res.data.data && res.data.data.poolError){

				            		swal("Job Deployment Failed!", '', "error")
				            	}
				    			console.log({deploying:pool,result:res.data})
				                self.fetch()
				            })
				            .catch(error => {
				                console.log(error);
				            });
						})

					}).catch((e) => {
						console.log('deploy failed, Error ',e)
						throw(e)
					// self.setState({errorMessage:e})
					});
                    break;
                case "no":
                	// console.log({deployNo:job,value})
                    break;
                default:

                // console.log({deployHuh:job,value})
            }
        });


    }

    terminate(pool,callback){
        let self = this

        swal("Are your sure you want to terminate? " + pool, {
            buttons: {
                no: {
                    text: "Cancel",
                    value: "no",
          			className: "btn-primary"
                },
                yes: {
                    text: "Terminate",
                    value: "yes",
          			className: "btn-danger"
                }
            }
        }).then(value => {

            switch (value) {
                case "yes":
				        axios
				            .get("/api/terminate/"+pool)
				            .then(res => {
				    			console.log({deploying:pool,result:res.data})
				                self.fetchJobs(callback)
				            })
				            .catch(error => {
				                console.log(error);
				            });
                    break;
                case "no":
                	// console.log({deployNo:job,value})
                    break;
                default:

                // console.log({deployHuh:job,value})
            }
        });
    }
    delete(job,callback){
        let self = this

        swal("Are your sure you want to delete?\nJob: '"+job.title+"'", {
            buttons: {
                no: {
                    text: "Cancel",
                    value: "no",
          			className: "btn-primary"
                },
                yes: {
                    text: "Delete",
                    value: "yes",
          			className: "btn-danger"
                }
            }
        }).then(value => {

            switch (value) {
                case "yes":

					axios.put('/api/jobs/'+job.objectId,{deleted:self.state.ut.objectId}).then( (data) => {
						self.fetchJobs(()=>{})

					}).catch((e) => {
						console.log('deploy failed, Error ',e)
						throw(e)
					});
                    break;
                case "no":
                	// console.log({deployNo:job,value})
                    break;
                default:

                // console.log({deployHuh:job,value})
            }
        });

    }


    render() {
        let self = this
        console.log({u:self.state.users,j:self.state.jobs})

		let newJobButton = null

		// if (this.state.ut.isActive) {
			newJobButton = (
				<ol className="breadcrumb float-right float-sm-right">
					<li style={{marginLeft:'20px'}}>            
						<button type="button" className="btn btn-block btn-success" onClick={()=>{self.setState({showModal:true,modalType:'new'})}}>New</button>
					</li>
				</ol>
  			)
		// }

		let jobModal = null
		if(self.state.showModal){

			if(self.state.modalType==='new'){
				jobModal = (

						<Modal>
							<div className="modal">
								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState({showModal:false},self.fetchJobs)
								}}></div>
								<NewJob 
									close={()=>{
										self.setState({showModal:false},self.fetchJobs)
									}} />
							</div>
						</Modal>
				)
			}
			else if(self.state.modalType==='deploy'){

				jobModal = (

						<Modal>
							<div className="modal">
								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState({showModal:false},self.fetchJobs)
								}}></div>
								<DeploymentOptions
									onDeploy={()=>{
										self.setState({showModal:false},()=>{
											self.fetchJobs(()=>{
												let j = self.state.selectedJob
												self.deploy(j.objectId,j.pool)
											})
										})										
									}}
									job={self.state.selectedJob} 
									close={()=>{
										self.setState({showModal:false},self.fetchJobs)
									}} />
							</div>
						</Modal>
				)
			}
			else if(self.state.modalType==='details'){

				jobModal = (

						<Modal>
							<div className="modal">
								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState({showModal:false},self.fetchJobs)
								}}></div>
								<DeploymentDetails
									terminate={(tpool)=>{
										self.terminate(tpool,()=>{
											self.setState({showModal:false,selectedJob:null})
										})
									}}
									job={self.state.selectedJob} 
									close={()=>{
										self.setState({showModal:false,selectedJob:null},self.fetchJobs)
									}} />
							</div>
						</Modal>
				)
			}
		}

		let pendingRunningJobs = (self.state.jobs||[]).filter((j)=>{
			return j.status != 'complete'
		})
		let finishedJobs = (self.state.jobs||[]).filter((j)=>{
			return j.status === 'complete'
		})

		let pendingJobsSection = null
		if(pendingRunningJobs && pendingRunningJobs.length){
			pendingJobsSection = (

				<section className="content">
					<div className="card card-solid">
						<div className="card-body pb-0">
							<h3 className="text-dark" style={{marginTop:'-10px',paddingLeft:'5px'}}>
								Pending/Running Jobs
							</h3>


							<div className="col-12 row d-flex align-items-stretch" style={{marginTop:'10px'}}>
							{pendingRunningJobs.map((j,jx)=>{

								let detailsButton = null
								let deployButton = null
								let terminateButton = null
								
								if(!j.status || !j.status.length){
									deployButton = (
										<a onClick={(e)=>{
											self.setState({showModal:true,modalType:'deploy',selectedJob:j})
											// self.deploy(j.objectId,j.pool)
										}} className="btn btn-sm btn-success" style={{color:'white'}}>
											Deploy
										</a>
									)
								}
								else{
									detailsButton = (
										<a onClick={(e)=>{
											self.setState({showModal:true,modalType:'details',selectedJob:j})
											// self.deploy(j.objectId,j.pool)
										}} className="btn btn-sm btn-primary" style={{color:'white'}}>
											Details
										</a>
									)
									terminateButton = (
										<a onClick={(e)=>{
											// self.setState({showModal:true,modalType:'details',selectedJob:j})
											self.terminate(j.pool)
										}} className="btn btn-sm btn-danger mr-2" style={{color:'white'}}>
											Terminate
										</a>
									)	
								}

								let user = self.state.users.filter((u)=>{return u.objectId===j.user})[0]||{}
								let currentStatus = j.userTerminated?'TERMINATED':(j.status || 'not-run')

								let nodes=""
								let cpuPerNode=""
								let elapsedStr=""

								if(self.state.poolStatus[j.pool]){
									let ps = self.state.poolStatus[j.pool]
									let cStatus = ps.status
									if(cStatus && cStatus.length){
										currentStatus = cStatus
									}
									cpuPerNode = 'CPU per node: ' + j.cpuPerNode
									nodes = 'Nodes: ' + ps.nodes + ' / '+ ps.targetNodes
									if(ps.creationTime){
										let now = new Date()
										let elapsed = (now-new Date(ps.creationTime))/(1000*60*60)
										elapsed = Math.floor(elapsed*100)/100
										elapsedStr = 'Elapsed: ' + elapsed.toFixed(2) + ' hrs'
									}
								}
								else{
									if(j.cpuPerNode!=null){
										cpuPerNode = 'CPU per node: ' + j.cpuPerNode
									}
									if(j.nodeCount!=null){
										nodes = 'Nodes to Deploy: ' + j.nodeCount 
									}
								}
          						return(
									<div key={'j_'+j.objectId} className="col-3 col-sm-12 col-md-6 d-flex align-items-stretch">
										<div className="card bg-light" style={{width: '100%'}}>
											<div className="card-header text-muted border-bottom-0">
												<div className="row">
													<div className="col-6">
														<img src={user.image} style={{height:'40px',marginLeft:'-15px',marginTop:'-7px',marginRight:'5px'}} />
														{[user.firstName||'',user.lastName||''].join(' ').trim()} 
													</div>
													<div className="col-6 text-right">
														{currentStatus} 
													</div>
												</div>
											</div>
											<div className="card-body pt-0">
												<div className="row">
													<div className="col-6">
														<h2 className="lead">
															{(j.title||'').trim()}
														</h2>
													</div>
												</div>
											</div>
											<div className="card-footer">
												<div className="text-left" style={{float:'left',lineHeight:'1em',fontSize:'.7em'}}>
														{cpuPerNode}<br/>
														{nodes}<br/>
														{elapsedStr}
												</div>
												<div className="text-right">
													{terminateButton}
													{deployButton}
													{detailsButton}
												</div>
											</div>
										</div>
									</div>
          						)
							})}
							</div>
						</div>
					</div>
				</section>
			)
		}

		let finishedJobsSection=null
		if(finishedJobs && finishedJobs.length){
			finishedJobsSection = (

				<section className="content">
					<div className="card card-solid">
						<div className="card-body pb-0">
							<h3 className="text-dark" style={{marginTop:'-10px',paddingLeft:'5px'}}>
								Finished Jobs
							</h3>

							<table id="example1" className="table table-bordered table-striped" style={{marginBottom:'2vh'}}>
								<thead>
								{
									// <tr>
									// 	<th>Date</th>
									// 	<th>Title</th>
									// 	<th>Code</th>
									// 	<th>User</th>
									// 	<th>Files</th>
									// 	<th>&nbsp;</th>
									// </tr>
								}
								</thead>
								<tbody>
								{finishedJobs.map((j,jx)=>{
									let user = self.state.users.filter((u)=>{return u.objectId===j.user})[0]||{}

									let packageButton=null
									let summaryButton=null
									let resultButton=null

									if(j.packageUrl){
										packageButton = (
											<a href={j.packageUrl} target="_blank" className="btn btn-sm btn-primary mr-2" style={{color:'white'}}>
												Package
											</a>
										)
									}
									if(j.summaryUrl){
										summaryButton = (
											<a href={j.summaryUrl} target="_blank" className="btn btn-sm btn-primary mr-2" style={{color:'white'}}>
												Summary
											</a>
										)
									}
									if(j.resultUrl){
										resultButton = (
											<a href={j.resultUrl} target="_blank" className="btn btn-sm btn-primary mr-4" style={{color:'white'}}>
												Result
											</a>
										)
									}

									let elapsedStr = null
									let displayDate = j.createdAt
									if(j.poolStart && j.poolEnd){
										displayDate = j.poolStart.iso
										elapsedStr = elapsedTime(new Date(j.poolEnd.iso),new Date(j.poolStart.iso))
									}
									console.log({jjj:j})
									return(
										<tr key={'r_'+j.objectId}>
											<td style={{lineHeight:'1em'}}>
												{displayDate.split('T')[0].replace('T',' ')}<br/>
												{elapsedStr}
											</td>
											<td style={{lineHeight:'1em'}}>
												{(j.title||'').trim()}<br />
												{(j.billingCode||'').trim()}
											</td>
											<td style={{lineHeight:'1em'}}>
												{[user.firstName||'',user.lastName||''].join(' ').trim()}
												{j.userTerminated?'Terminated by user':''}
											</td>
											<td className="text-right">
												
												{packageButton}
												{summaryButton}
												{resultButton}

												<a onClick={()=>{
													self.delete(j)
												}} className="btn btn-sm btn-danger mr-2" style={{color:'white'}}>
													Delete
												</a>
											</td>
										</tr>
									)
								})}
								</tbody>
							</table>

						</div>
					</div>
				</section>
			)
		}
		else{
			finishedJobsSection = (

				<section className="content">
					<div className="card card-solid">
						<div className="card-body pb-0">
							<h3 className="text-dark" style={{marginTop:'-10px',paddingLeft:'5px'}}>
								No Jobs
							</h3>
						</div>
					</div>
				</section>
			)
		}
    	return (
    		<div className="content-wrapper">
  				{/* Content Header (Page header) */}
				<div className="content-header">
					<div className="container-fluid">
						<div className="row">
							<div className="col-6 col-sm-6">
								<h1 className="m-0 text-dark">
									Jobs
								</h1>
							</div>
							<div className="m-0 col-6 col-sm-6">
								{newJobButton}
							</div>							
						</div>{/* /.row */}
					</div>{/* /.container-fluid */}
				</div>{/* /.content-header */}
  

  				{/* Main content */}
  				{pendingJobsSection}
  				{finishedJobsSection}

				{jobModal}
			</div>
		);
	}
}

export default Jobs;

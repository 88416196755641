import React, { Component } from "react";
import AvatarEditor from 'react-avatar-editor'

import Modal from "../../modal";

export default class PhotoEditor extends Component {
    constructor(props) {
        super(props);

        this.state={
        	showModal:false,
		    image: null,
		    allowZoomOut: false,
		    position: { x: 0.5, y: 0.5 },
		    scale: 1,
		    rotate: 0,
		    borderRadius: 0,
		    preview: null,
		    width: 200,
		    height: 200,
		    buttonText:this.props.text||'New Photo'
        }
        this.initialState={
        	showModal:false,
		    image: null,
		    allowZoomOut: false,
		    position: { x: 0.5, y: 0.5 },
		    scale: 1,
		    rotate: 0,
		    borderRadius: 0,
		    preview: null,
		    width: 200,
		    height: 200,
        }
    }

	setEditorRef(editor){
		if (editor) this.editor = editor
	}
	// handleNewImage(e){
	// 	this.setState({ image: e.target.files[0] })
	// }
	// handlePositionChange(position){
	// 	this.setState({ position })
	// }
	// handleScale(e){
	// 	const scale = parseFloat(e.target.value)
	// 	this.setState({ scale })
	// }

	// rotateLeft(e){
	// 	e.preventDefault()

	// 	this.setState({rotate: this.state.rotate - 90})
	// }

	// rotateRight(e){
	// 	e.preventDefault()
	// 	this.setState({rotate: this.state.rotate + 90})
	// }

	// handleBorderRadius(e){
	// 	const borderRadius = parseInt(e.target.value)
	// 	this.setState({ borderRadius })
	// }

	handleXPosition(e){
		const x = parseFloat(e.target.value)
		this.setState({ position: { ...this.state.position, x } })
	}

	handleYPosition(e){
		const y = parseFloat(e.target.value)
		this.setState({ position: { ...this.state.position, y } })
	}

	handleWidth(e){
		const width = parseInt(e.target.value)
		this.setState({ width })
	}

	handleHeight(e){
		const height = parseInt(e.target.value)
		this.setState({ height })
	}
	logCallback(e) {
		// eslint-disable-next-line
		console.log('callback', e)
	}

    render() {
    	let self=this

    	let editorModal = null
    	if(self.state.showModal){
			editorModal = (

						<Modal>
							<div className="modal">

								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState(self.initialState)
								}}></div>
								<div style={{position:'relative',background:'white',width:'90vw',zIndex:1000}}>
									
									  <div className="content-header">
									    <div className="container-fluid">
									      <div className="row mb-2">
									        <div className="col-sm-6">
									          <input name="newImage" type="file" onChange={(e)=>{
									          	console.log({f:e.target.files[0]})
									          	self.setState({ image: e.target.files[0] })
									          }} />
									        </div>{/* /.col */}
									      </div>{/* /.row */}
									    </div>{/* /.container-fluid */}
									  </div>


									    <section className="content">
									      <div className="row">
									        <div className="col-1"/>

									      	<div className="col-3">
									      		<div className="row">
									      			<div className="col-4">
									      				Zoom:
									        		</div>
									      			<div className="col-8">										      	
														<input
															name="scale"
															type="range"
															onChange={(e)=>{
																const scale = parseFloat(e.target.value)
																self.setState({ scale })
															}}
															min="1"
															max="3"
															step="0.01"
															defaultValue="1"
														/>

									        		</div>
								        		</div>
									      		<div className="row">
									      			<div className="col-4">
									      				Rotate:
									        		</div>
									      			<div className="col-8">	
														<input
															name="rotate"
															type="range"
															onChange={(e)=>{
																const rotate = parseFloat(e.target.value)
																self.setState({ rotate })
															}}
															min="-180"
															max="180"
															step="0.01"
															defaultValue="0"
														/>

									        		</div>
								        		</div>
									      		<div className="row">
									      			<div className="col-4">
									      				Size:
									        		</div>
									      			<div className="col-8">	
														<input
															name="rotate"
															type="range"
															onChange={(e)=>{
																const size = parseFloat(e.target.value)
																self.setState({ height:size,width:size })
															}}
															min="120"
															max="480"
															step="1"
															defaultValue={self.state.width}
														/>

									        		</div>
								        		</div>
									      		<div className="row">
									      			<div className="col-12" style={{textAlign:'center'}}>
									      				{self.state.width}px
									        		</div>
								        		</div>
								        	{
									     //  		<div className="row">
									     //  			<div className="col-3">
									     //  				Border:
									     //    		</div>
									     //  			<div className="col-9">	
														// <input
														// 	name="scale"
														// 	type="range"
														// 	onChange={(e)=>{
														// 		const borderRadius = parseInt(e.target.value)
														// 		self.setState({ borderRadius })
														// 	}}
														// 	min="0"
														// 	max="50"
														// 	step="1"
														// 	defaultValue="0"
														// />

									     //    		</div>
								      //   		</div>
								        	}
									      	</div>
									      	<div className="col-8">
									            <AvatarEditor
									              ref={(editor)=>{self.editor=editor}}
									              scale={parseFloat(this.state.scale)}
									              width={this.state.width}
									              height={this.state.height}
									              position={this.state.position}
									              onPositionChange={(position)=>{self.setState({ position })}}
									              rotate={parseFloat(this.state.rotate)}
									              borderRadius={this.state.width / (100 / this.state.borderRadius)}
									              onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
									              onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
									              onImageReady={this.logCallback.bind(this, 'onImageReady')}
									              image={this.state.image}
									              className="editor-canvas"
									            />
									        </div>
									      </div>
									      <div className="row" style={{marginTop:'3vmin',marginBottom:'3vmin'}}>
									        <div className="col-1"/>
									        <div className="col-3">
									          	<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.setState(self.initialState)}}>Cancel</button>
									        </div>
									        <div className="col-4"/>
									        <div className="col-3">
									          		<button type="button" className="btn btn-block btn-success" onClick={(e)=>{
    													const imageB64 = self.editor.getImageScaledToCanvas().toDataURL()
    													const rect = self.editor.getCroppingRect()
    													if(self.props.onImageBase64){
    														self.props.onImageBase64({imageB64,rect})	
    													}
    													if(self.props.onImageBlob){
    														var blobBin = atob(imageB64.split(',')[1]);
															var array = [];
															for(var i = 0; i < blobBin.length; i++) {
															    array.push(blobBin.charCodeAt(i));
															}
															var blobFile=new Blob([new Uint8Array(array)], {type: this.state.image.type});
															self.props.onImageBlob({blobFile})
    													}
    													self.setState(self.initialState)
									          		}}>OK</button>
									        </div>
									        <div className="col-1"/>
									      </div>
									    </section>


								</div>
							</div>
						</Modal>
			)
    	}
    	return(
    		<React.Fragment>
    			<input type="button" value={self.state.buttonText} className="btn btn-block btn-success" onClick={()=>{self.setState({showModal:true})}} />
    			{editorModal}
    		</React.Fragment>
    	)
       
    }
}